import styled from 'styled-components';
import { colorsPalettes } from '@similarweb/styles';

export const Instructions = styled.p`
    font-size: .875rem;
    line-height: 1.5;
    font-weight: 400;
    padding: 0 5%;
    color: ${colorsPalettes.carbon[400]};
`;

export const DeviceAddress = styled.span`
    font-weight: 600;
`;

export const OtherOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    & > div {
        margin: 0;
    }
`;