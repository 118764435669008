import { FunctionComponent } from 'react';
import ErrorPage from '../../pages/Error/ErrorPage';
import LoginPage from '../../pages/Login/LoginPage';
import SsoPage from '../../pages/Sso/SsoPage';
import ForgotPasswordPage from '../../pages/ForgotPassword/ForgotPasswordPage';
import ForgotPasswordConfirmationPage from '../../pages/ForgotPasswordConfirmation/ForgotPasswordConfirmationPage';
import ResetPasswordPage from '../../pages/ResetPassword/ResetPasswordPage';
import DeviceVerificationPage from '../../pages/DeviceVerification/DeviceVerificationPage';
import TwoFactorPage from '../../pages/TwoFactor/TwoFactorPage';
import BlockedPage from '../../pages/Blocked/BlockedPage';
import LockoutPage from '../../pages/Lockout/LockoutPage';
import InternalTwoFactorPage from '../../pages/TwoFactorInternal/InternalTwoFactorPage';
import InternalTwoFactorMethodPage from '../../pages/TwoFactorInternal/InternalTwoFactorMethodPage';
import ErrorHeader from '../../pages/Error/images/header.svg';
import ForgotPasswordHeaderImg from '../../pages/ForgotPassword/images/header.svg';
import ForgotPasswordConfirmHeaderImg from '../../pages/ForgotPasswordConfirmation/images/header.svg';
import ResetPasswordHeaderImg from '../../pages/ResetPassword/images/header.svg';
import DeviceVerificationHeaderImg from '../../pages/DeviceVerification/images/header.svg';
import TwoFactorHeaderImg from '../../pages/TwoFactor/images/header.svg';
import BlockedHeaderImg from '../../pages/Blocked/images/header.svg';
import LockoutHeaderImg from '../../pages/Lockout/images/header.svg';
import { IPage } from '../../components/PageWrapper/types';
import { asI18nKey, I18nKey } from '../utilities/Localization';
import { PageTemplate } from '../../components/PageTemplates/types';

export interface IPageConfig {
    title: I18nKey;
    routes: Array<string>;
    component: FunctionComponent<IPage>;
    template: keyof typeof PageTemplate;
    authenticationState?: keyof typeof AuthenticationState;
    trackingPageName?: string;
    trackingCategory?: string;
    headerImage?: string;
    showFooter?: boolean;
    maxContentWidth?: number;
}

export enum IdentityPage {
    Error,
    Login,
    Sso,
    ForgotPassword,
    ForgotPasswordConfirmation,
    ResetPassword,
    DeviceVerification,
    Blocked,
    Lockout,
    InternalTwoFactor,
    InternalTwoFactorMethod,
    TwoFactor
}

export enum AuthenticationState {
    Irrelevant,
    Authenticated,
    Unauthenticated
}

export const homepage: keyof typeof IdentityPage = 'Login';

const pagesConfig: Record<keyof typeof IdentityPage, IPageConfig> = {
    'Error': {
        title: asI18nKey('error_page.page_title'),
        routes: [ '/error/:code' ],
        component: ErrorPage,
        template: 'Identity',
        trackingPageName: 'Error',
        headerImage: ErrorHeader
    },
    'Login': {
        title: asI18nKey('login.page_title'),
        routes: [ '/account/login' ],
        component: LoginPage,
        template: 'Identity',
        trackingPageName: 'Login',
        trackingCategory: 'Login',
        showFooter: true,
        authenticationState: 'Unauthenticated'
    },
    'Sso': {
        title: asI18nKey('login_sso.page_title'),
        routes: [ '/account/login/sso' ],
        component: SsoPage,
        template: 'Identity',
        trackingPageName: 'LoginSSO',
        trackingCategory: 'Login',
        showFooter: true,
        authenticationState: 'Unauthenticated'
    },
    'ForgotPassword': {
        title: asI18nKey('forgot_password.page_title'),
        routes: [ '/account/password/forgot' ],
        component: ForgotPasswordPage,
        template: 'Identity',
        trackingPageName: 'ForgotPassword',
        trackingCategory: 'Password reset',
        headerImage: ForgotPasswordHeaderImg,
        authenticationState: 'Unauthenticated'
    },
    'ForgotPasswordConfirmation': {
        title: asI18nKey('forgot_password.confirm.page_title'),
        routes: [ '/account/password/forgot/confirm' ],
        component: ForgotPasswordConfirmationPage,
        template: 'Identity',
        trackingPageName: 'ForgotPasswordConfirmation',
        headerImage: ForgotPasswordConfirmHeaderImg,
        authenticationState: 'Unauthenticated'
    },
    'ResetPassword': {
        title: asI18nKey('reset_password.page_title'),
        routes: [ '/account/password/reset' ],
        component: ResetPasswordPage,
        template: 'Identity',
        trackingPageName: 'ResetPassword',
        trackingCategory: 'Set up password',
        headerImage: ResetPasswordHeaderImg,
        authenticationState: 'Unauthenticated'
    },
    'DeviceVerification': {
        title: asI18nKey('device_verification.page_title'),
        routes: [ '/account/device-verification' ],
        component: DeviceVerificationPage,
        template: 'Identity',
        trackingPageName: 'DeviceAuthentication',
        trackingCategory: 'New device detected',
        headerImage: DeviceVerificationHeaderImg,
        authenticationState: 'Unauthenticated'
    },
    'TwoFactor': {
        title: asI18nKey('2fa.page_title'),
        routes: [ '/account/2fa' ],
        component: TwoFactorPage,
        template: 'Identity',
        trackingPageName: 'TwoFactorAuthentication',
        trackingCategory: 'Two factor authentication',
        headerImage: TwoFactorHeaderImg,
        authenticationState: 'Unauthenticated'
    },
    'InternalTwoFactor': {
        title: asI18nKey('2fa.page_title'),
        routes: [ '/account/2fa/internal' ],
        component: InternalTwoFactorPage,
        template: 'Card',
        maxContentWidth: 340,
        showFooter: true,
        authenticationState: 'Authenticated'
    },
    'InternalTwoFactorMethod': {
        title: asI18nKey('2fa.page_title'),
        routes: [ '/account/2fa/internal/:method' ],
        component: InternalTwoFactorMethodPage,
        template: 'Card',
        maxContentWidth: 340,
        showFooter: true,
        authenticationState: 'Authenticated'
    },
    'Blocked': {
        title: asI18nKey('blocked.page_title'),
        routes: [ '/account/blocked' ],
        component: BlockedPage,
        template: 'Identity',
        trackingPageName: 'Blocked',
        headerImage: BlockedHeaderImg,
        authenticationState: 'Unauthenticated'
    },
    'Lockout': {
        title: asI18nKey('lockout.page_title'),
        routes: [ '/account/lockout' ],
        component: LockoutPage,
        template: 'Identity',
        trackingPageName: 'Lockout',
        headerImage: LockoutHeaderImg,
        authenticationState: 'Unauthenticated'
    }
};

export default pagesConfig;
