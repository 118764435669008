import { FunctionComponent, useContext } from 'react';
import LanguagesDropdown from '../../LanguagesDropdown/LanguagesDropdown';
import RegistrationFooter from '../../RegistrationFooter/RegistrationFooter';
import I18nContext from '../../../common/context/I18nContext';
import PageMask from '../../PageWrapper/PageMask/PageMask';
import FadeTransition from '../../FadeTransition/FadeTransition';
import { useLocation } from 'react-router-dom';
import AppLogoImg from '../images/app_logo.svg';
import { IPageTemplate } from '../types';
import ErrorAlertToast from '../../ErrorAlertToast/ErrorAlertToast';
import { getPath } from '../../../common/utilities/Navigation';
import {
    Layout,
    ContentContainer,
    Content,
    LanguagesDropdownContainer,
    AppLogo,
    HeaderImg,
    Title,
    Background
} from './IdentityPageTemplate.style';

const IdentityPageTemplate: FunctionComponent<IPageTemplate> = props => {
    const { changeLanguage } = useContext(I18nContext);
    const location = useLocation();
    const transitionDelay = 500;

    return (
        <Background>
            <AppLogo
                className={'accessible'}
                href={getPath('Login')}
            >
                <img
                    src={AppLogoImg}
                    alt={''}
                />
            </AppLogo>
            <Layout>
                <ContentContainer>
                    <Content>
                        <LanguagesDropdownContainer>
                            <LanguagesDropdown onChange={changeLanguage} />
                        </LanguagesDropdownContainer>
                        <FadeTransition
                            msTime={transitionDelay}
                            dependencies={[ location ]}
                        >
                            {!!props.headerImg && (
                                <HeaderImg
                                    src={props.headerImg}
                                    alt={''}
                                />
                            )}
                            <Title>{props.title}</Title>
                            <ErrorAlertToast
                                show={!!props.serverAlert}
                                alert={props.serverAlert}
                                automationName={`page-alert-${props.serverAlert?.type}`}
                            />
                            {props.children}
                        </FadeTransition>
                    </Content>
                </ContentContainer>
                {props.showFooter && (
                    <FadeTransition
                        msTime={transitionDelay}
                        dependencies={[ location ]}
                    >
                        <RegistrationFooter automationName={'registration-button'} />
                    </FadeTransition>
                )}
                <PageMask enabled={!!props.pageDisabled} />
            </Layout>
        </Background>
    );
}

export default IdentityPageTemplate;