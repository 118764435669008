import { FunctionComponent, useState, useRef, FocusEvent, KeyboardEvent } from 'react';
import { IFormField } from '../types';
import TextField from '../TextField/TextField';
import DropdownList from './List/DropdownList';
import { InputContainer, Container, ArrowIcon, OptionImage } from './Dropdown.style';
import ArrowIco from '../images/arrow.svg';
import { IDropdownOptionConfig } from './types';
import useTrigger from '../../../common/hooks/UseTrigger';

interface IDropdown extends IFormField {
    options: Array<IDropdownOptionConfig>;
    listWidth?: number;
}

const Dropdown: FunctionComponent<IDropdown> = props => {
    const [selectedOption, setSelectedOption] = useState<IDropdownOptionConfig>();
    const [isOpen, setOpen] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputFieldRef = useRef<HTMLDivElement>(null);
    const withdrawListFocusTrigger = useTrigger();

    /**
     * Activate when any option is selected.
     * This function automatically closes the dropdown list.
     *
     * @param {IDropdownOptionConfig} option - The selected option
     */
    const onOptionSelect = (option: IDropdownOptionConfig): void => {
        setSelectedOption(option);
        setOpen(false);
    }

    /**
     * Activate when the dropdown list component loses focus.
     *
     * @param {FocusEvent} ev - Default focus event
     */
    const onDropdownListBlur = (ev: FocusEvent): void => {
        let target = ev?.relatedTarget;
        let inputField = inputFieldRef?.current;
        if (target !== inputField && !inputField?.contains(target)) setOpen(false);
    }

    /**
     * Activate when the dropdown element tries to lose focus.
     * This function checks whether it lost its focus to a child element,
     * and if so, it does nothing.
     */
    const onBlur = (ev: FocusEvent<HTMLDivElement>): void => {
        let container = containerRef?.current;
        let nextEl = ev.relatedTarget;
        let elContained = container?.contains?.(nextEl);

        if (!elContained) onDropdownListBlur(ev);
    }

    /**
     * Toggle the list's open state.
     */
    const toggleList = (): void => setOpen(prevState => !prevState);

    /**
     * Activate when a key is pressed while the list is open.
     * This function closes the list if the pressed key is Escape.
     *
     * @param {KeyboardEvent} ev - Default event object
     */
    const onKeyDown = (ev: KeyboardEvent<HTMLDivElement>): void => {
        if (ev.key !== 'Escape' || !isOpen) return;

        ev.stopPropagation(); //avoid closing the modal
        setOpen(false);
        withdrawListFocusTrigger.fire();
    }

    return (
        <Container
            ref={containerRef}
            open={isOpen}
            hasImage={!!selectedOption?.image}
            width={props.size?.width}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
        >
            <InputContainer
                ref={inputFieldRef}
                onClick={() => setOpen(!isOpen)}
            >
                {!!selectedOption?.image && (
                    <OptionImage
                        src={selectedOption.image}
                        alt={selectedOption.text}
                    />
                )}
                <TextField
                    { ...props }
                    type={'Text'}
                    readonly
                    initialValue={selectedOption?.value || selectedOption?.text || ''}
                    size={{ width: 100 }}
                    onEnterPress={toggleList}
                    shouldFocus={withdrawListFocusTrigger.active}
                    onKeyPress={key => key === 'ArrowDown' && toggleList()}
                    { ...props.automationElementAttr }
                />
                <ArrowIcon
                    src={ArrowIco}
                    alt={''}
                    open={isOpen}
                    hasLabel={!!props.label}
                />
            </InputContainer>
            {isOpen && (
                <DropdownList
                    options={props.options}
                    isOpen={isOpen}
                    listWidth={props.listWidth}
                    fieldHeight={props.size?.height || 35}
                    onSelect={onOptionSelect}
                    onBlur={onDropdownListBlur}
                />
            )}
        </Container>
    );
}

export default Dropdown;