import { FunctionComponent } from 'react';
import { ActionLabel, Container, Content, Label, SimilarwebIcon } from './RegistrationFooter.style';
import SimilarwebIco from './images/similarweb.svg';
import I18n from '../I18n/I18n';
import withAutomationTesting, { AutomationTarget } from '../../common/HOC/WithAutomationTesting';
import useAPI from '../../common/hooks/UseAPI';
import { redirectToRegistration } from './RegistrationFooterService';

interface IRegistrationFooter extends AutomationTarget {}

const RegistrationFooter: FunctionComponent<IRegistrationFooter> = (props) => {
    const api = useAPI();

    return (
        <Container
            className={'accessible-tight'}
            onClick={() => api(() => redirectToRegistration())}
            {...props.automationElementAttr}
        >
            <Content>
                <SimilarwebIcon
                    src={SimilarwebIco}
                    alt={''}
                />
                <Label>
                    <I18n>registration_footer.prefix</I18n>
                    <ActionLabel>
                        <I18n>registration_footer.sign_up</I18n>
                    </ActionLabel>
                </Label>
            </Content>
        </Container>
    );
}

export default withAutomationTesting(RegistrationFooter);
