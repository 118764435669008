import { FunctionComponent, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FieldType, FieldVerificationConfig, IFormField } from '../types';
import OpenEye from '../images/eye-open.svg';
import ClosedEye from '../images/eye-closed.svg';
import { HideIcon, InputField } from './TextField.style';
import FormField from '../FormField';
import { getPredefinedConfigByField } from '../../../common/utilities/InputVerification';
import useLocalization from '../../../common/hooks/UseLocalization';
import { asI18nKey } from '../../../common/utilities/Localization';

interface ITextField extends IFormField {
    type?: keyof typeof FieldType;
    canHide?: boolean;
}

const TextField: FunctionComponent<ITextField> = props => {
    const { translate } = useLocalization();
    const fieldRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(props.initialValue ?? '');
    const [actualType, setActualType] = useState<keyof typeof FieldType>(props.type || 'Text');
    const actualValue = useMemo<string>(() => props.initialValue || value, [props.initialValue, value]);
    const isHidden = useMemo<boolean>(() => actualType === 'Password', [actualType]);
    const canHide = useMemo<boolean>(() => props.type === 'Password' || !!props.canHide, [props.type, props.canHide]);
    const verificationConfig = useMemo<FieldVerificationConfig>(() => {
        const predefined = getPredefinedConfigByField(props.type || 'Text');
        let customConfig = props.verificationConfig;

        return customConfig || {
            ...predefined,
            customError: translate(predefined.customError || asI18nKey())
        };
    }, [props.verificationConfig, props.type]);

    useLayoutEffect(() => {
        !!props.initialValue && setValue(props.initialValue)
    }, [props.initialValue]);

    /**
     * Replace the input's characters with asterisks,
     * or revert them back to the original values.
     *
     * @param {boolean} flag - True to hide or false to show
     */
    const hideInput = (flag: boolean): void => {
        let originType = (props.type === 'Password' ? 'Text' : props.type) || 'Text';
        setActualType(flag ? 'Password' : originType);
    }

    const onKeyPress = (key: string): void => {
        if (!key) return;

        if (key === 'Enter') props.onEnterPress?.();
        props.onKeyPress?.(key);
    }

    return (
        <FormField
            { ...props }
            input={actualValue}
            verificationConfig={verificationConfig}
            size={{ width: props.size?.width, height: props.size?.height }}
            inputRef={fieldRef}
        >
            <InputField
                id={`input-${props.name}`}
                name={`input-${props.name}`}
                ref={fieldRef}
                type={FieldType[(actualType || 'Text') as keyof typeof FieldType]}
                autoComplete={actualType.toString().toLowerCase()}
                placeholder={props.placeholder}
                value={value}
                title={actualType !== 'Password' ? actualValue : ''}
                alt={actualValue}
                readOnly={!!props.readonly}
                disabled={!!props.disabled}
                filled={!!actualValue}
                canHide={canHide}
                height={props.size?.height}
                onChange={ev => setValue(ev.target.value)}
                onKeyDown={ev => onKeyPress(ev.key)}
                { ...props.automationElementAttr }
            />
            {canHide && (
                <HideIcon
                    className={'accessible-tight'}
                    tabIndex={0}
                    onClick={() => hideInput(!isHidden)}
                    onKeyDown={ev => ev.key === 'Enter' && hideInput(!isHidden)}
                >
                    <img
                        src={isHidden ? OpenEye : ClosedEye}
                        alt={isHidden ? 'show' : 'hide'}
                    />
                </HideIcon>
            )}
        </FormField>
    );
}

export default TextField;