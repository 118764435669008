import styled from 'styled-components';

export const Container = styled.div<{
    open: boolean;
    hasImage: boolean;
    width?: number;
}>`
    position: relative;
    width: ${props => props.width || 55}%;
    margin: 0 auto;
  
    & input:not(.search-bar) {
        --padding-left: 15px;
        --padding-right: 30px;

        width: calc(100% - var(--padding-left) - var(--padding-right));
        padding-left: var(--padding-left);
        padding-right: var(--padding-right);
        cursor: pointer;

        ${props => props.hasImage && `
            --padding-left: 45px;
        `}
      
        ${props => props.open && `
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        `}
    }
`;

export const InputContainer = styled.div`
    position: relative;
`;

export const OptionImage = styled.img`
    position: absolute;
    top: 50%;
    left: 10px;
    height: 25px;
    transform: translateY(-50%);
    z-index: 1;
`;

export const ArrowIcon = styled.img<{
    open: boolean;
    hasLabel: boolean;
}>`
    position: absolute;
    width: 10px;
    top: calc(50% + ${props => props.hasLabel ? 15 : 0}px);
    right: 7px;
    opacity: ${props => props.open ? 0 : 1};
    transform: translateY(-50%);
    pointer-events: none;
    cursor: pointer;
    transition: .2s;
`;

export const OptionContainer = styled.li`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
`;

export const OptionText = styled.p`
    text-align: left;
    font-size: .875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;