import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { CloseButton, Stripe, Text } from './AlertToast.style';
import { AlertType, IAlert } from './types';
import useTrigger from '../../common/hooks/UseTrigger';
import CloseIco from './images/close.svg';

interface IAlertToast {
    isVisible: boolean;
    alert: IAlert | undefined;
    onClose?: () => void;
}

const AlertToast: FunctionComponent<IAlertToast> = props => {
    const [closed, setClosed] = useState<boolean>(false);
    const alertExists = useMemo<boolean>(() => !!props.alert?.text, [props.alert]);
    const trembleTrigger = useTrigger(500);
    const showAlert = useMemo<boolean>(() => {
        return props.isVisible && alertExists && !closed;
    }, [props.isVisible, alertExists, closed]);

    const [alertsCount, setAlertsCount] = useState<number>(showAlert ? 1 : 0);

    useEffect(() => {
        setClosed(false);
        setAlertsCount(prev => alertExists ? prev + 1 : 0);
    }, [props.alert]);

    useEffect(() => {
        if (alertsCount > 1) trembleTrigger.fire();
    }, [alertsCount]);

    /**
     * Close the currently displayed alert.
     */
    const close = (): void => {
        props.onClose?.();
        setClosed(true);
        setAlertsCount(0);
    }

    return (
        <Stripe
            visible={showAlert}
            type={props.alert?.type ?? AlertType.Error}
        >
            <Text tremble={trembleTrigger.active}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: props.alert?.text || ''
                    }}
                />
            </Text>
            <CloseButton onClick={close}>
                <img
                    src={CloseIco}
                    alt={'close'}
                />
            </CloseButton>
        </Stripe>
    );
}

export default AlertToast;